import React from "react"
import { Link } from "react-router-dom";
import CoursePhoto2 from '../img/course-photo-2.png'

const CourseA0CTA = (props) => {
  return (
    <div>
        <div className={`course-1-cta-container ${props.class}`}>
            <div>
                <div>
                    <h2>
                        {props.creativeText}
                    </h2>
                </div>
                <img src={CoursePhoto2} alt="course-1-cta" className="course-1-cta-img"/>
            </div>
            <Link to={"/course-2"}><button>Записатись на курс <q>Ігри пам'яті</q></button></Link>
        </div>
    </div>
  )
};

export default CourseA0CTA;
