import React from "react"
import NastyaMain from "../img/nastya-main-3.png"
import WeInSocialNetwork from "./WeInSocialNetwork";
import ReviewSlider from "./ReviewSlider";
import LiveCourse from "./LiveCourse";
import CareService from "./CareService";
import CourseA0CTA from "./CourseA0CTA";
import CourseA0CTACreative from "./CourseA0CTACreative"
import Recommendations from "./Recommendations";
import { EmphasiseMontserrat, Beige } from "./CustomElements";

const Home = (props) => {

  return (
    <div>
        <div className="main-container">
          <div className="name-container">
            <div className="name-container__small">
              <h1>Анастасія Севастьянова</h1>
              <p className="">Викладач, що робить німецьку <EmphasiseMontserrat><Beige>твоєю</Beige></EmphasiseMontserrat> мовою.</p>
            </div>
            <CourseA0CTA />
          </div>
          <img src={NastyaMain} alt="nastya-main" />
        </div>
        <WeInSocialNetwork instagram={true} additionalClass={'small-margin'}/>
        <CareService class={'care-service-relative'}/>
        <Recommendations/>
        <CourseA0CTACreative class={'wholescreen columns'} creativeText={'Забуваєте слова вже через день-два? Запишіться на міні-курс "Ігри пам’яті" і дізнайтеся, як запам’ятовувати НАЗАВЖДИ!'} />
        <ReviewSlider />
        <LiveCourse courseDate="2025-01-10T00:00:00" courseName="Ігри пам'яті" />
    </div>
  )
};

export default Home;
