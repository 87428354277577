import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { Blue } from "./CustomElements";
import CoursePhoto2 from '../img/course-photo-2.png'

const CourseA0CTA = (props) => {
//     const targetDate = new Date("2024-11-10T00:00:00");
//   const targetDate2 = new Date("2024-11-20T00:00:00");
//   const targetDate3 = new Date("2024-11-22T00:00:00");

//   const calculateTimeLeft = (targetDate) => {
//     const difference = targetDate - new Date();
//     let timeLeft = {};

//     if (difference > 0) {
//       timeLeft = {
//         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         minutes: Math.floor((difference / 1000 / 60) % 60),
//         seconds: Math.floor((difference / 1000) % 60)
//       };
//     } else {
//       timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
//     }

//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
//   const [timeLeft2, setTimeLeft2] = useState(calculateTimeLeft(targetDate2));
//   const [timeLeft3, setTimeLeft3] = useState(calculateTimeLeft(targetDate3));

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(calculateTimeLeft(targetDate));
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft2(calculateTimeLeft(targetDate2));
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft3(calculateTimeLeft(targetDate3));
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);

//   const isTimeUp = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;
//   const isTimeUp2 = timeLeft2.days === 0 && timeLeft2.hours === 0 && timeLeft2.minutes === 0 && timeLeft2.seconds === 0;
//   const isTimeUp3 = timeLeft3.days === 0 && timeLeft3.hours === 0 && timeLeft3.minutes === 0 && timeLeft3.seconds === 0;

  return (
    <div>
        <div className={`course-1-cta-container ${props.class}`}>
            <div>
                <div>
                    <h2>
                        <Blue><strong>Авторський курс:</strong></Blue> <q>Ігри пам'яті</q> 
                    </h2>
                    <h2>
                        <Blue><strong>Коли старт:</strong></Blue> 10.01.25
                    </h2>
                    <h2>
                        <Blue><strong>Формат:</strong></Blue> Онлайн
                    </h2>
                    <h2>
                        <Blue><strong>Тривалість:</strong></Blue> 3 дні
                    </h2>
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> 770грн
                    </h2>
                    {/* {!isTimeUp &&           
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> 2450грн
                    </h2>}
                    {isTimeUp && !isTimeUp2 &&
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> 2950грн
                    </h2>}
                    {isTimeUp && isTimeUp2 && !isTimeUp3 &&
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> 3250грн
                    </h2>} */}
                </div>
                <img src={CoursePhoto2} alt="course-1-cta" className="course-1-cta-img"/>
            </div>
            <Link to={"/course-2"}><button>Детальніше</button></Link>
        </div>
    </div>
  )
};

export default CourseA0CTA;
