import Navbar from "./elements/Navbar";
import "./styles/style.css"
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./elements/Footer"
import Home from "./elements/Home"
import AboutMe from "./elements/AboutMe";
import Course1 from "./elements/Course1.jsx"
import RefundPolicy from './elements/RefundPolicy.jsx'
import PrivacyPolicy from './elements/PrivacyPolicy.jsx'
import ContractForm from './elements/ContractForm.jsx'
import ScrollToTop from './elements/ScrollToTop.jsx'
import FAQ from "./elements/FAQ.jsx";
import Contacts from "./elements/Contacts.jsx";
import Courses from "./elements/Courses.jsx"
import Course2 from "./elements/Course-2.jsx"

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ScrollToTop />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/aboutme" element={<AboutMe />} />
            <Route exact path="/refund-policy" element={<RefundPolicy />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/contract-form" element={<ContractForm />} />
            <Route exact path="/course-1" element={<Course1 />} />
            <Route exact path="/course-2" element={<Course2 />} />
            <Route exact path="/faq" element={<FAQ />} />                            
            <Route exact path="/contacts" element={<Contacts />} />       
            <Route exact path="/courses" element={<Courses />} />                                                                                                         
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
