import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import TysonImage from "../img/image-tyson.png"
import CareService from "./CareService";
import { Blue } from "./CustomElements";

const Navbar = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
  }, [location])

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
    if(!menuOpen){
      document.body.style.overflow = 'hidden'
    }
    if(menuOpen){
      document.body.style.overflow = 'auto'
    }
  };

  return (
    <div>
        <div className="navbar">
            <h2><Link to="/">Deutsch mit <Blue>Tyson</Blue><img src={TysonImage} alt="image-tyson" className="tyson-image-navbar" /></Link></h2>
            <div className="navbar-menu">
                <li><Link to="/course-2">Ігри <Blue>пам'яті</Blue> </Link></li>
                <li><Link to="/aboutme">Про мене</Link></li>
                <li><Link to="/courses">Усі Курси</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><Link to="/contacts">Контакти</Link></li>
            </div>
            <input type="checkbox" className="menu-toggle" id="menu-toggle" checked={menuOpen} onChange={handleToggle}/>
            <label htmlFor="menu-toggle" className="burger-menu-button">
              <span></span>
              <span></span>
              <span></span>
            </label>
            <div className="navbar-menu__burger-container">
              <li><Link to="/course-2">Ігри <Blue>пам'яті</Blue> </Link></li>
              <li><Link to="/aboutme">Про мене</Link></li>
              <li><Link to="/courses">Усі Курси</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/contacts">Контакти</Link></li>
              <CareService />
            </div>
        </div>
    </div>
  )
};

export default Navbar;
