import React, { useRef, useState, useEffect } from 'react';
import '../styles/ReviewSlider.css';
import Draggable from './Draggable';
import ReviewImage1 from "../img/review1.jpg"
import ReviewImage3 from "../img/review2.jpg"
import ReviewImage4 from "../img/review3.jpg"
import ReviewImage5 from "../img/review4.jpg"
import ReviewImage6 from "../img/review5.jpg"
import ReviewImage7 from "../img/review6.jpg"
import ReviewImage8 from "../img/review7.jpg"
import ReviewImage9 from "../img/review8.jpg"
import ReviewImage10 from "../img/review9.jpg"
import ReviewImage11 from "../img/review10.jpg"
import ReviewImage12 from "../img/review11.jpg"
import ReviewImage13 from "../img/review12.jpg"
import ReviewImage14 from "../img/review13.jpg"
import ReviewImage15 from "../img/review14.jpg"
import ReviewImage16 from "../img/review15.jpg"
import ReviewImage17 from "../img/review16.jpg"
import ReviewImage18 from "../img/review17.jpg"
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Blue, EmphasiseEvolventa } from './CustomElements';

const Reviews = () => {

  const reviews = [
    {
      name: "Олена",
      text: "Я до Вас Perfekt взагалі не розуміла. Була як дика людина, коли не розумієш що відбувається. Зараз розбираюся. Main Vokabular ist nicht gut. | дуже не вистачає знань з граматики. Не знала інструментів вивчення і запам'ятовування. Дякуючи прямим етерам і лекціям на курсі з Вами швидко все міняється. Дякую за курс! Вчити стає цікавіше і легше. Багато узагальненої інфармації в деталях, легко сприймається.",
      image: ReviewImage1,
      altText: " review 2"     
    },
    {
      name: "Діана",
      text: "Завжди із позитивом, любовʼю до мови і неймовірною адаптацією до потреб і ситуацій, дякую, Настюш, за чудово проведені роки зайняттями німецької ❤️ Дякую тобі ❤️",
      image: ReviewImage3,
      altText: " review 3"    
    },
    {
      name: "Анастасія",
      text: "Коли я приїхала в Німеччину - твої уроки реально врятували мене. Я взагалі повним нулем була. Завдяки твоїм лекціям я швидко доросла до походу в супермаркет 😂, а зараз вже доволі легко спілкуюсь будь з ким. Дякую 🥰",
      image: ReviewImage4,
      altText: " review 4"     
    },
    {
      name: "Деніс",
      text: "Не зміг бути онлайн але все дивлюсь онлайн. Все дуже круто і як завжди мені подобається як складні речі ти пояснюється як для дітей просто. Я тобі не можу передати як я почав буквально все досліджувати як дитина навколо мене: «що написано на цій шоколадці», «що значить напис на цьому знаку де можно паркуватися» Я не впізнаю себе но мені чорт забери це подобається. Нарешті я у стані «пилососу» і всмоктую знання! Спасибі тобі!",
      image: ReviewImage5,
      altText: " review 5"    
    },
    {
      name: "Арина",
      text: "Насте, я досі вдячна тобі неймовірно за той місяць, коли ти знайшла позапланово час і змогла з мого слабенького А1 зробити стійкий А2, я навіть не могла повірити результатам екзамену!! Твій чіткий підхід і інструкції направили мене в найефективнішу сторону. Ти змогла швидко знайти мої слабкі сторони, та підтягнути їх, і я досі користуюсь твоїми лайфхами. Вчитися було не просто, але в кайф!",
      image: ReviewImage6,
      altText: " review 6" 
    },
    {
      name: "Вікторія",
      text: "Я не дуже добре вмію писати довгі повідомлення, але я здала Гете С1 завдяки тобі! 90 зі 100! Я в захваті від результату і мені дуже подобалось займатись з тобою. А ще я дуже рада, що Карина до тебе попала 😂 я їй тебе радила давно, але в тебе завжди місць не було)) вона сказала, що ви вже займаєтесь! Заздрю їй, якщо чесно 😂 з тобою дуже цікаво ❤️",
      image: ReviewImage7,
      altText: " review 7"    
    },
    {
      name: "Катерина",
      text: "Анастасія привіт! Мене звати Катя, я з марафону. Дуже дякую за курс! Я шукала щось справді схоже щоб запамʼятовувати лексику і особливо дієслова бо вони ніяк не закарбовуються у мене в памʼяті. Марафон перевершив очікування, япере організовую свою роботу з вивченням нових слів і потроху добавляю мнемотехніки які ви давали. Появився шанс продвинутися в напрямку цілей з вивченням німецької.",
      image: ReviewImage8,
      altText: " review 8"    
    },
    {
      name: "Наталі",
      text: "Анастасія, дуже дякую за курс. Кропітка робота, а для нас корисна і важлива. 👏🏽🌷 Дуже цікаво було слухати лекції. Не помічала як пролітав час. Ваші приклади - це окремий вид насолоди! Дякую 🌸",
      image: ReviewImage9,
      altText: " review 9"     
    },
    {
      name: "Марія",
      text: "Дякую Вам дуже!! До Вас німецьку я вчила довго і болісно: і в школі, і на курсах. Але тільки Ви зробили так, щоб я нарешті хоч якось заговорила! Я Вам дуже вдячна! За мотивацію, за знання і за терпеливе розʼяснення. Іноді по 100 разів 😅",
      image: ReviewImage10,
      altText: " review 10"
    },
    {
      name: "Софія",
      text: "Ми познайомились з тобою в далекому 2020, коли я не змогла здати частину говоріння на в2. Ти понаднормово внесла мене в свій графік і я здала!! З тобою моє говоріння просто вийшло на новий рівень. А аудіювання взагалі 🔥🔥🔥 Дякую!!!",
      image: ReviewImage11,
      altText: " review 11"
    },
    {
      name: "Ілля",
      text: "Дякую тобі Настя, що я тебе знайшов! Зараз зʼявилося стільки викладачів німецької, але у багатьох не вистачає того вогника, який є у тебе! Ти можеш і «дати пендель» і бути доброю Lehrerin з милим котиком. За ці 3 місяці ми інтенсивно підготувалися до одного з найважчих екзаменів з німецької і підтягнули всі прогалини, які були у мене. Ми зробили акцент на Sprechen і правильно оптимізували наповненість занять. Дякую тобі за цей досвід, за твою підтримку!! Викладачка це не суровий директор, це в першу чергу - опора студента. І ти завжди була моєю опорою 💪🏻",
      image: ReviewImage12,
      altText: " review 12"
    },
    {
      name: "Софія",
      text: "Доречі, курс просто кайф!!! Мені трохи важкувато, бо я звикла не більше години на чомусь концентруватись, але по кількості інформації і технік ти перевершила всіх і все!!! ",
      image: ReviewImage13,
      altText: " review 13"
    },
    {
      name: "Марія",
      text: "Привіт, я сдала В2 (з сильною мігренню яка проявилась на hören und schreiben, там трохи по пунктам видно, що не максимальний бал) дуже допомогли те що ми з тобою проходили коли началась і більшість нашого матеріалу я використовувала. Із за вагітності навіть не могла випити заспокійливого або винішка, щоб трохи заспокоїтись, бо іспити для мене завжди стрес, але навіть не дивлячись на це я здала 😍",
      image: ReviewImage14,
      altText: " review 14"
    },
    {
      name: "Валерія",
      text: "Мабуть, в моєму випадку ,це була ДОЛЯ!! Стиль викладання та подача матеріалу нікого не залишить байдужим! З впевненістю можу сказати,що такого дієвого та цікавого вивчення мови дуже важко знайти! Ти супер! Дякую тобі! ❤️",
      image: ReviewImage15,
      altText: " review 15"
    },
    {
      name: "Надія",
      text: "Дуже дякую за курс! Це знахідка 😍😍 Особливо вдячна за круті техніки запамʼятовування, які я, як студентка, буду застосовувати не тільки для німецької. Всі вебінари проходили на одному подиху, завжди було цікаво слухати, дуже доречно підібрані приклади із життя, які легко запамʼяталися. Окремо дякую за поради щодо подолання страху говорити. Він в мене був, завдяки Вам перестала боятися. Дякую ❤️",
      image: ReviewImage16,
      altText: " review 16"
    },
    {
      name: "Ольга",
      text: "Анастасія, супер! Усе зрозуміло, так цікаво! Я навіть ніколи їх не перекладала , просто говорила по відчуттях. Пояснюєш дуже добре, дуже зрозуміло, дуже живою мовою! Дякуємо тобі таке шалене старання достукатися до наших мізків. Дійсно є закономірність. Мозг реагує на частинки маленькі з який потім виходить щось велике..тепер боремося за засвоєння",
      image: ReviewImage17,
      altText: " review 17"
    },
    {
      name: "Олексій",
      text: "Привіт, курси просто супер! Ти все дуже добре пояснюєш, завжди з цікавинками, з краплинкою сміху, з тобою приємно розмовляти, бо всі твої учні - твої друзі 😁👌🏼 Світові події німецькою, це круто! Розказуєш про німецьку все що треба знати і більше! Мені дуже подобається ☺",
      image: ReviewImage18,
      altText: " review 18"
    }
  ];

  const extendedReviews = [
    ...reviews,
    ...reviews,
    ...reviews,
  ];

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1200);
  const [supportsScroll, setSupportsScroll] = useState(true); // state to detect scrolling
  const sliderRef = useRef(null);
  const sliderControlsRef = useRef(null); // New ref for slider controls
  
  const isIOSOrMacOS = () => {
    return /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;
  };

  const isChrome = () => {
    const userAgent = navigator.userAgent;
    return /Chrome/.test(userAgent) && !/Edge|Edg|OPR/.test(userAgent);
  };

  useEffect(() => {
    const handleResize = () => setIsTablet(window.innerWidth <= 1200);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      const scrollWidth = reviewWidth * (extendedReviews.length / 3); 
      sliderRef.current.scrollTo({
        left: scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [isTablet]);


  useEffect(() => {
    // Check for scroll behavior support
    const checkScrollSupport = () => {
      const supportsScroll = 'scrollBehavior' in document.documentElement.style;
      setSupportsScroll(supportsScroll);
    };
    checkScrollSupport();
  }, []);

  useEffect(() => {
    // Dynamically set display to flex if scroll is not supported
    if (sliderControlsRef.current) {
      if (!supportsScroll || isIOSOrMacOS()) {
        sliderControlsRef.current.style.display = 'flex';
      } else {
        sliderControlsRef.current.style.display = isTablet ? 'flex' : 'none';
      }
    }

    // Apply or remove scroll-snap-type based on scroll support
    if (sliderRef.current) {
      if (!supportsScroll || isIOSOrMacOS() || isTablet) {
        sliderRef.current.style.scrollSnapType = 'x mandatory'; // Disable scroll snap if smooth scroll not supported
      } else {
        sliderRef.current.style.scrollSnapType = 'none'; // Enable scroll snap if supported
      }
    }
  }, [supportsScroll, isTablet]);

  const scrollLeft = () => {
    if (sliderRef.current && isChrome) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: -reviewWidth,
        behavior: 'auto',
      });
    }
    if (sliderRef.current && !isChrome) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: -reviewWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current && isChrome) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: reviewWidth,
        behavior: "auto",
      });
    }
    if (sliderRef.current && !isChrome) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: reviewWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      const scrollLeft = sliderRef.current.scrollLeft;
      const scrollWidth = reviewWidth * extendedReviews.length;

      if (scrollLeft >= scrollWidth - sliderRef.current.clientWidth) {
        sliderRef.current.scrollTo({
          left: reviewWidth,
          behavior: 'smooth',
        });
      } else if (scrollLeft <= 0) {
        sliderRef.current.scrollTo({
          left: scrollWidth - reviewWidth * 2,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if(sliderRef.current){
    const container = sliderRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [extendedReviews.length]);

  return (
    <div className="reviews-wrapper">
      <div className="reviews-container">
        <h2 className="reviews-header">Відгуки наших студентів:</h2>
        {isTablet || !supportsScroll || isIOSOrMacOS() ? (
          <>
            <div className="reviews-slider" ref={sliderRef}>
              {extendedReviews.map((review, index) => (
                <div className="review" key={index}>
                <div className="image-name"> 
                  <img className="review-image" src={review.image} alt={review.altText} draggable={false}/>
                  <h2><EmphasiseEvolventa><Blue>{review.name}</Blue></EmphasiseEvolventa></h2>        
                </div>
                  <p>{review.text}</p>
              </div>
              ))}
            </div>
            <div className="slider-controls" ref={sliderControlsRef}>
              <button className="slider-button left" onClick={scrollLeft}>
                <FontAwesomeIcon icon={faArrowLeft} color='dodgerblue' size='2x'/>
              </button>
              <button className="slider-button right" onClick={scrollRight}>
                <FontAwesomeIcon icon={faArrowRight} color='dodgerblue' size='2x'/>
              </button>
            </div>
          </>
        ) : (
          <Draggable rootClass="reviews-slider">
            {extendedReviews.map((review, index) => (
              <div className="review" key={index}>
              <div className="image-name"> 
                <img className="review-image" src={review.image} alt={review.altText} draggable={false}/>
                <h2><EmphasiseEvolventa><Blue>{review.name}</Blue></EmphasiseEvolventa></h2>        
              </div>
                <p>{review.text}</p>
              </div>
            ))}
          </Draggable>
        )}
      </div>
    </div>
  );
};

export default Reviews;
